import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./component/Home"
import Navbar from "./component/Navbar"

const App = () => {
    return (
        <>
            <BrowserRouter>
            <Navbar/>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<div>About page</div>} />
                </Routes>
            </BrowserRouter>
        </>
    )
}
export default App
//  rafce