import w1 from "../../assets/projects/web/w1.png"
import w2 from "../../assets/projects/web/w2.png"
import w3 from "../../assets/projects/web/w3.png"
const WebData = [
    {
        id: "1",
        img: w1,
        pname: 'Portfolio Template',
        link: "https://portfolio-dummy-nikhil.netlify.app"
    },
    {
        id: "2",
        img: w2,
        pname: 'News Website Template',
        link: "https://akhbarwala.netlify.app/"
    },
    {
        id: "3",
        img: w3,
        pname: 'Service Based Website',
        link: "https://digikings.netlify.app/"
    }
]

export default WebData;