import React from "react";
import { NavLink, Link } from "react-router-dom";
import "../css/navbar.css"
import img from "../assets/2.png"
import { RiMenu2Fill, RiCloseFill } from "react-icons/ri";
// import 

const Navbar = () => {
    React.useEffect(()=>{
        let lnk = document.querySelectorAll('.link');
        for (let i = 0; i < lnk.length; i++) {
            lnk[i].addEventListener('click', function () {
                console.log('shi chl rha h');
                document.getElementById('navlink').classList.remove('active')
                document.getElementById('toggle').classList.remove('active')
            })
        }
    })

    const navToggle = (e) => {
        let toggle = document.getElementById('toggle'),
            links = document.getElementById('navlink')
        toggle.classList.toggle('active')
        links.classList.toggle('active')
    }

    return (
        <>
            <nav className="navbar xl:mx-auto md:relative sticky top-0 md:z-auto z-20 bg-[#060606] flex justify-between items-center 2xl:w-[1350px] 2xl:mx-auto py-5">
                <Link className="relative md:left-20 left-10" to="/">
                    <img src={img} className="md:w-20 w-16" />
                </Link>
                <ul className="md:relative md:py-0 py-16 flex md:flex-row flex-col md:text-start text-center md:text-sm text-xl md:right-20 md:h-auto h-[100vh] md:bg-transparent bg-[#252525] md:top-0 top-[75px] md:w-auto w-full md:z-auto z-10" id="navlink">
                    <a className="mx-2 md:my-0 my-1.5 md:text-[#ccd0d8] text-[#84a4fc] md:py-1 px-2 link" href="#home">Home</a>
                    {/* add active class to add underline to the home tab */}
                    <a className="mx-2 md:my-0 my-1.5 md:text-[#ccd0d8] text-[#84a4fc] md:py-1 px-2 link" href="#service">Service</a>
                    <a className="mx-2 md:my-0 my-1.5 md:text-[#ccd0d8] text-[#84a4fc] md:py-1 px-2 link" href="#skill">Skills</a>
                    <a className="mx-2 md:my-0 my-1.5 md:text-[#ccd0d8] text-[#84a4fc] md:py-1 px-2 link" href="#work">My Works</a>
                    <a className="mx-2 md:my-0 my-1.5 md:text-[#ccd0d8] text-[#84a4fc] md:py-1 px-2 link" href="#contact">Contact Me</a>
                </ul>
                <button className="toggle sm:hidden grid text-2xl relative w-8 h-8 place-items-center border border-[#1463f3] right-10" id="toggle" onClick={(e) => {
                    navToggle(e);
                }}>
                    <RiMenu2Fill className="absolute open" />
                    <RiCloseFill className="absolute close" />
                </button>
            </nav>
        </>
    )
}
export default Navbar