import "../css/card.css"
import { RxExternalLink } from "react-icons/rx";

const Card = (props) => {
    return (
        <>
            <div className="innerc mx-auto relative overflow-hidden xl:w-96 xl:h-48 lg:w-[90%] lg:h-[270px] sm:h-[200px] sm:w-[100%]">
                <div className="hover hover:scale-105 duration-300 ease-in-out">
                    <img src={props.img} className="z-0 w-full xl:h-48 sm:h-[200px] lg:h-[270px]" />
                    <div className="text-white overlay w-full flex justify-center items-center h-full flex-col">
                        <p className="mb-2 text-xl">{props.pname}</p>
                        <a href={props.link} target="_blank" className="px-4 py-2 flex items-center justify-center border-[#fff] border duration-300 ease-in-out hover:border-[#1463f3] text-sm rounded-sm">Visit <RxExternalLink className="ml-1" /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card;