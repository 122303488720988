import React from 'react'
import Typewriter from "typewriter-effect"
import "../css/index.css"
import img from "../assets/banner.jpeg"
import { Link } from 'react-router-dom'
import { FaFacebookF, FaGithub, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { TiMessages } from "react-icons/ti";
import { BsCamera2, BsLink } from "react-icons/bs";
import { HiCode } from "react-icons/hi"
import { ImLocation2 } from "react-icons/im"
import { IoMdCall } from "react-icons/io"
import { MdEmail } from "react-icons/md"
import { AiOutlineAntDesign } from "react-icons/ai"
import resume from "../assets/Resume.pdf"
import NormalData from "./project_data/NormalData"
import Card from './Card'
import WebData from './project_data/WebData'
import ReactData from './project_data/ReactData'

const Home = () => {

    React.useEffect(() => {
        let dot = document.getElementsByClassName('dot'),
            num = document.getElementsByClassName('num'),
            circle = document.getElementsByClassName('circle'),
            num1 = document.getElementsByClassName('num1')
        document.addEventListener('mousemove', (e) => {
            // console.log(document.getElementsByClassName('dot'));
            // console.log(e.pageY,window.innerWidth);
            if (window.innerWidth > 1100 && e.pageY > 1000) {
                document.getElementById('pie').classList.add('fadeRight')
                document.getElementById('bar').classList.add('fadeLeft')
                setTimeout(() => {
                    for (let i = 0; i < dot.length; i++) {
                        dot[i].classList.add('anime');
                        num[i].classList.add('fadeIn');
                        num1[i].classList.add('fadeIn')
                        circle[i].classList.add('fadeIn');
                    }
                }, 700)
            }
        })
        if (window.innerWidth < 1100) {
            for (let i = 0; i < dot.length; i++) {
                dot[i].classList.add('anime');
                num[i].classList.add('fadeIn');
                num1[i].classList.add('fadeIn')
                circle[i].classList.add('fadeIn');
            }
        }
    }, [])
    return (
        <>
            <section className='banner' id='home'>
                <div className='flex justify-center items-center lg:py-24 py-8 2xl:w-[1350px] xl:mx-auto'>
                    <div className='flex justify-center md:flex-row flex-col-reverse items-center'>
                        <div className='content md:w-3/5 md:px-0 px-7 sm:px-20 md:text-left text-center md:mt-0 mt-10 md:ml-20'>
                            {/* lg:text-[2rem] text-[1.5rem] */}
                            <h1 className='lg:text-4xl sm:h-auto h-[110px] text-3xl mb-3'>
                                Hello, I'm<span id='typewrier' className="lg:text-5xl bg-slate-200 text-4xl text-[#1463f3]"><Typewriter
                                    options={{
                                        strings: ['Nikhil Kumar Gupta!', 'a Frontend Developer!'],
                                        autoStart: true,
                                        pauseFor: 2500,
                                        loop: true,
                                    }}
                                /></span>
                            </h1>
                            <p className='mb-4 lg:text-base md:w-[80%] text-sm'>
                                Hi, I have an experience of 1 year in frontend designing and frontend development. Eager to explore more to build innovative and cutting-edge business solutions for the impressive suite of clients within its global reach.
                            </p>
                            <div className='btn flex justify-center md:justify-start'>
                                <a href={resume} download className='px-7 bg-[#84a4fc] text-black font-medium py-3.5 rounded-sm border-[#84a4fc] duration-200 ease-in-out border hover:shadow-md hover:shadow-[#84a4fc] hover:bg-transparent mr-3 hover:text-[#e6e6e6] sm:text-base text-xs'>Download CV</a>
                                <a href='#contact' className='flex sm:text-base text-xs items-center text-[#84a4fc] py-3.5 px-7'>Let's Talk<TiMessages className='sm:text-xl text-base ml-2' /></a>
                            </div>
                            <div className='social mt-5'>
                                <ul className='flex justify-center md:justify-start'>
                                    <li>
                                        <a href='https://www.linkedin.com/in/nikhil-kumar-gupta-3901861b6' className='grid place-items-center p-3 text-xl border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaLinkedinIn /></a>
                                    </li>
                                    <li>
                                        <a href='https://github.com/Nikhil-1402' className='grid place-items-center p-3 text-xl border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaGithub /></a>
                                    </li>
                                    <li>
                                        <a href='https://twitter.com/devil_1402' className='grid place-items-center p-3 text-xl border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaTwitter /></a>
                                    </li>
                                    <li>
                                        <a href='https://www.facebook.com/profile.php?id=100010660059501' className='grid place-items-center p-3 text-xl border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaFacebookF /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='pic md:w-2/5 flex'>
                            <div className='img-container lg:w-96 xs:w-72 xs:h-72 w-64 h-64 sm:w-72 sm:h-72 border-[18px] border-double relative border-[#84a4fc] rounded-full overflow-hidden lg:h-96'>
                            {/* border-double border-[16px] outline-8 outline-[#84a4fc] outline-offset-4 outline */}
                                <img src={img} className="absolute sm:-top-8 -top-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='service' id='service'>
                <div className='2xl:w-[1350px] 2xl:mx-auto lg:mb-8 lg:px-20 md:px-10 px-8 py-10 flex flex-col'>
                    <h1 className='lg:text-4xl text-3xl lg:ml-0 md:ml-10 hed w-fit'>Servi<span className='text-[#1463f3]'>ces</span></h1>
                    <div className='grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-4 lg:mt-24 mt-10'>
                        <div className='sercard mx-auto lg:w-96 lg:h-96 sm:w-[85%] md:h-[350px] sm:h-[300px] w-[100%] h-[250px] overflow-hidden relative'>
                            <div className='absolute z-[1] w-full h-full flex flex-col sm:p-8 px-4 text-center justify-center items-center'>
                                <AiOutlineAntDesign className='lg:text-7xl text-5xl sm:mb-5 mb-3 ico' />
                                <p className='sm:mb-3 mb-2 sm:text-base text-sm'>
                                    I have designed various websites some of them are mentioned in my works. The websited are designed according to the user requirements, after precise series of researches about the purpose of the website, the designs of the website are implemented by me.
                                </p>
                                <a href='#contact' className='border px-5 py-2 rounded hover:border-[#1463f3] text-white hover:shadow-sm duration-200 ease-in-out hover:shadow-[#1463f3] sm:text-base text-sm'>Contact Me</a>
                            </div>
                        </div>
                        <div className='sercard mx-auto lg:w-96 lg:h-96 sm:w-[85%] md:h-[350px] sm:h-[300px] w-[100%] h-[250px] overflow-hidden relative'>
                            <div className='absolute z-[1] w-full h-full flex flex-col sm:p-8 px-4 text-center justify-center items-center'>
                                <HiCode className='lg:text-7xl text-5xl sm:mb-5 mb-3 ico' />
                                <p className='sm:mb-3 mb-2 sm:text-base text-sm'>
                                    The coding part of the website is one of the trickiest part during development. After researching about the effective way to develop the application cutting the cost and time to minimum. I keep these things in my mind during development.
                                </p>
                                <a href='#contact' className='border px-5 py-2 rounded hover:border-[#1463f3] text-white hover:shadow-sm duration-200 ease-in-out hover:shadow-[#1463f3] sm:text-base text-sm'>Contact Me</a>
                            </div>
                        </div>
                        <div className='sercard mx-auto lg:w-96 lg:h-96 sm:w-[85%] md:h-[350px] sm:h-[300px] w-[100%] h-[250px] overflow-hidden relative'>
                            <div className='absolute z-[1] w-full h-full flex flex-col sm:p-8 px-4 text-center justify-center items-center'>
                                <BsCamera2 className='lg:text-7xl text-5xl sm:mb-5 mb-3 ico' />
                                <p className='sm:mb-3 mb-2 sm:text-base text-sm'>
                                    Hey!!! I would like to acknowledge you that this was my first hobby. I worked on my own pictures. Although I did not worked on any editing projects but I have a good knowledge of how the picture should be? How it should be enhanced to its excellence?
                                </p>
                                <a href='#contact' className='border px-5 py-2 rounded hover:border-[#1463f3] text-white hover:shadow-sm duration-200 ease-in-out hover:shadow-[#1463f3] sm:text-base text-sm'>Contact Me</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='skill 2xl:w-[1350px] 2xl:mx-auto lg:mb-8 lg:px-20 md:px-10 xs:px-8 py-10' id='skill'>
                <h1 className='lg:text-4xl text-3xl lg:ml-0 md:ml-10 xs:ml-0 ml-8 hed w-fit'>Ski<span className='text-[#1463f3]'>lls</span></h1>
                <div className='grid lg:grid-cols-3 grid-cols-1 items-center gap-5 lg:px-3 lg:mt-24 mt-12'>
                    <div id='pie' className='set grid xs:grid-cols-2 grid-cols-1 xs:w-max w-[90%] xs:gap-10 gap-1 lg:mx-0 mx-auto xs:ml-auto ml-7'>
                        <div className='elem xs:hidden flex items-center mb-3.5'>
                            <label className='xs:text-sm text-xs w-36'>HTML/CSS <span className='text-xs text-[#ccd0d87a]'>(85%)</span></label>
                            <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                <div className='bg-[#1463f3] w-[85%] h-full rounded-lg'></div>
                            </div>
                        </div>
                        <div className='elem xs:hidden flex items-center mb-3.5'>
                            <label className='xs:text-sm text-xs w-36'>TAILWIND CSS
                                <span className='text-xs text-[#ccd0d87a]'>(78%)</span></label>
                            <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                <div className='bg-[#1463f3] w-[78%] h-full rounded-lg'></div>
                            </div>
                        </div>
                        <div className='elem xs:hidden flex items-center mb-3.5'>
                            <label className='xs:text-sm text-xs w-36'>JAVASCRIPT <span className='text-xs text-[#ccd0d87a]'>(65%)</span></label>
                            <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                <div className='bg-[#1463f3] w-[65%] h-full rounded-lg'></div>
                            </div>
                        </div>
                        <div className='elem xs:hidden flex items-center mb-3.5'>
                            <label className='xs:text-sm text-xs w-36'>REACT JS
                                <span className='text-xs text-[#ccd0d87a]'>(45%)</span></label>
                            <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                <div className='bg-[#1463f3] w-[45%] h-full rounded-lg'></div>
                            </div>
                        </div>
                        <div className='skill-card xs:flex hidden xs:mb-0 mb-1 hover:scale-[1.02] duration-300 ease-in-out relative w-40 h-44 justify-center'>
                            <div className='percent relative w-36 h-36' style={{ '--num': 85 }}>
                                {/* dot class m z -10 hoga abhi thodi der m */}
                                <div className='dot z-10 absolute inset-0.5'></div>
                                <svg className='relative w-36 h-36 -rotate-90'>
                                    <circle className='w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                    <circle className='circle w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                </svg>
                                <div className='num absolute inset-0 flex justify-center items-center flex-col'>
                                    <h2 className='text-5xl font-semibold'>85<span className='text-[#ccd0d8a9] text-2xl'>%</span></h2>
                                </div>
                            </div>
                            <span className='low num1 absolute font-medium text-[#ccd0d8a9] bottom-1 text-sm'>HTML & CSS</span>
                        </div>
                        <div className='skill-card xs:flex hidden xs:mb-0 mb-1 hover:scale-[1.02] duration-300 ease-in-out relative w-40 h-44 justify-center'>
                            <div className='percent relative w-36 h-36' style={{ '--num': 78 }}>
                                {/* dot class m z -10 hoga abhi thodi der m */}
                                <div className='dot z-10 absolute inset-0.5'></div>
                                <svg className='relative w-36 h-36 -rotate-90'>
                                    <circle className='w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                    <circle className='circle w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                </svg>
                                <div className='num absolute inset-0 flex justify-center items-center flex-col'>
                                    <h2 className='text-5xl font-semibold'>78<span className='text-[#ccd0d8a9] text-2xl'>%</span></h2>
                                </div>
                            </div>
                            <span className='low num1 absolute font-medium text-[#ccd0d8a9] bottom-1 text-sm'>TAILWIND CSS</span>
                        </div>
                        <div className='skill-card xs:flex hidden xs:mb-0 mb-1 hover:scale-[1.02] duration-300 ease-in-out relative w-40 h-44 justify-center'>
                            <div className='percent relative w-36 h-36' style={{ '--num': 65 }}>
                                {/* dot class m z -10 hoga abhi thodi der m */}
                                <div className='dot z-10 absolute inset-0.5'></div>
                                <svg className='relative w-36 h-36 -rotate-90'>
                                    <circle className='w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                    <circle className='circle w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                </svg>
                                <div className='num absolute inset-0 flex justify-center items-center flex-col'>
                                    <h2 className='text-5xl font-semibold'>65<span className='text-[#ccd0d8a9] text-2xl'>%</span></h2>
                                </div>
                            </div>
                            <span className='low num1 absolute font-medium text-[#ccd0d8a9] bottom-1 text-sm'>JAVASCRIPT</span>
                        </div>
                        <div className='skill- xs:flex hidden xs:mb-0 mb-1 hover:scale-[1.02] duration-300 ease-in-out relative w-40 h-44 justify-center'>
                            <div className='percent relative w-36 h-36' style={{ '--num': 45 }}>
                                {/* dot class m z -10 hoga abhi thodi der m */}
                                <div className='dot z-10 absolute inset-0.5'></div>
                                <svg className='relative w-36 h-36 -rotate-90'>
                                    <circle className='w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                    <circle className='circle w-full h-full fill-transparent stroke-2 stroke-[#ccd0d816] translate-y-[2px] translate-x-[2px]' cx={70} cy={70} r={70}></circle>
                                </svg>
                                <div className='num absolute inset-0 flex justify-center items-center flex-col'>
                                    <h2 className='text-5xl font-semibold'>45<span className='text-[#ccd0d8a9] text-2xl'>%</span></h2>
                                </div>
                            </div>
                            <span className='low num1 absolute font-medium text-[#ccd0d8a9] bottom-1 text-sm'>REACT JS</span>
                        </div>
                    </div>
                    <div id='bar' className='col-span-2 xs:px-0 px-8 h-max lg:ml-20 xl:ml-12 lg:mx-0 mx-auto md:w-[90%]'>
                        <p className='sm:text-base text-sm'>
                            I have worked hard on my skills by making various projects based on my level of experience and knowledge at the time. My personal skills are, I am a diligent worker, I respond instantly to an issue, I have good grasping ability, I am a quick learner, I have problem solving ability. I use Vs Code for developing applications, snapseed and lightroom for editing pictures. I have a sound knowledge of Canva also. I love to play guitar, sing songs, writing, poetry, playing computer games, cooking, fitness coach. I am also an ahtlete. I love to be fit and like to play various sports. I like to interact with other people.
                        </p>
                        <div className='progrs mt-6'>
                            <div className='elem flex items-center mb-3.5'>
                                <label className='xs:text-sm text-xs w-36'>jQuery <span className='text-xs text-[#ccd0d87a]'>(50%)</span></label>
                                <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                    <div className='bg-[#1463f3] w-[50%] h-full rounded-lg'></div>
                                </div>
                            </div>
                            <div className='elem flex items-center mb-3.5'>
                                <label className='xs:text-sm text-xs w-36'>Bootstrap <span className='text-xs text-[#ccd0d87a]'>(70%)</span></label>
                                <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                    <div className='bg-[#1463f3] w-[70%] h-full rounded-lg'></div>
                                </div>
                            </div>
                            <div className='elem flex items-center mb-3.5'>
                                <label className='xs:text-sm text-xs w-36'>Photography <span className='text-xs text-[#ccd0d87a]'>(80%)</span></label>
                                <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                    <div className='bg-[#1463f3] w-[80%] h-full rounded-lg'></div>
                                </div>
                            </div>
                            <div className='elem flex items-center mb-3.5'>
                                <label className='xs:text-sm text-xs w-36'>Photo Editing <span className='text-xs text-[#ccd0d87a]'>(70%)</span></label>
                                <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                    <div className='bg-[#1463f3] w-[70%] h-full rounded-lg'></div>
                                </div>
                            </div>
                            <div className='elem flex items-center mb-3.5'>
                                <label className='xs:text-sm text-xs w-36'>Video Editing <span className='text-xs text-[#ccd0d87a]'>(60%)</span></label>
                                <div className=' bg-[#ccd0d81f] rounded-lg h-2 ml-3 w-[60%]'>
                                    <div className='bg-[#1463f3] w-[60%] h-full rounded-lg'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='work 2xl:w-[1350px] 2xl:mx-auto min-h-[100vh] lg:px-20 md:px-10 px-8 py-10' id='work'>
                <h1 className='lg:text-4xl text-3xl lg:ml-0 md:ml-10 hed w-fit'>My W<span className='text-[#1463f3]'>orks</span></h1>
                <div className=''>
                    <h3 className='mt-10 text-xl'>HTML CSS and JS</h3>
                    <div className='htmlcss grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-5 py-6'>
                        {
                            NormalData.map((val) => {
                                return (
                                    <div className='' key={val.id}>
                                        <Card
                                            img={val.img}
                                            pname={val.pname}
                                            link={val.link}

                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                    <h3 className='mt-10 text-xl'>Web Projects</h3>
                    <div className='web grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-5 py-6'>
                        {
                            WebData.map((val) => {
                                return (
                                    <div className='' key={val.id}>
                                        <Card
                                            img={val.img}
                                            pname={val.pname}
                                            link={val.link}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                    <h3 className='mt-10 text-xl'>React Projects</h3>
                    <div className='web grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-5 py-6'>
                        {
                            ReactData.map((val) => {
                                return (
                                    <div className='' key={val.id}>
                                        <Card
                                            img={val.img}
                                            pname={val.pname}
                                            link={val.link}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

            </section>


            <section className='contact' id='contact'>


                <div className='2xl:w-[1350px] 2xl:mx-auto lg:pb-20 lg:px-20 md:px-10 px-8 py-10'>
                    <h1 className='lg:text-4xl text-3xl lg:ml-0 md:ml-10 hed w-fit'>Cont<span className='text-[#1463f3]'>act Us</span></h1>

                    <div className='grid md:grid-cols-2 grid-cols-1 gap-5 mt-16'>
                        <div className='con flex flex-col justify-center md:mb-0 mb-5'>
                            <a href='https://goo.gl/maps/HXBumWAZSV9sZnf86' target="_blank" className='flex xs:w-[350px] w-full md:mx-0 mx-auto md:py-6 py-3 md:my-2 my-1 items-center'>
                                <span className='p-4 rounded-sm bg-white text-2xl'><ImLocation2 className='text-[#060606]' /></span>
                                <div className='content ml-4'>
                                    <h3 className='xs:text-2xl text-xl text-[#1463f3] font-semibold'>Address</h3>
                                    <p className='text-sm'>Shyam Nagar, Kalyanpur, Lucknow, 226022</p>
                                </div>
                            </a>
                            <a href='tel:+919696125044' target="_blank" className='flex xs:w-[350px] w-full md:mx-0 mx-auto md:py-6 py-3 md:my-2 my-1 items-center'>
                                <span className='p-4 rounded-sm bg-white text-2xl'><IoMdCall className='text-[#060606]' /></span>
                                <div className='content ml-4'>
                                    <h3 className='xs:text-2xl text-xl text-[#1463f3] font-semibold'>Phone</h3>
                                    <p className='text-sm'>+91 969 612 5044</p>
                                </div>
                            </a>
                            <a href='mailto:nikhildeveloper1402@gmail.com' target="_blank" className='flex xs:w-[350px] w-full md:mx-0 mx-auto md:py-6 py-3 md:my-2 my-1 items-center'>
                                <span className='p-4 rounded-sm bg-white text-2xl'><MdEmail className='text-[#060606]' /></span>
                                <div className='content ml-4'>
                                    <h3 className='xs:text-2xl text-xl text-[#1463f3] font-semibold'>Email</h3>
                                    <p className='text-sm break-all'>nikhildeveloper1402@gmail.com</p>
                                </div>
                            </a>
                        </div>

                        <div className='form md:mx-0 mx-auto pt-7 md:px-14 px-6 lg:w-[80%] md:w-full xs:w-[80%] w-full rounded-sm bg-white'>
                            <h3 className='text-2xl mb-8 text-[#060606] font-medium'>Let's Con<span className='text-[#1463f3]'>nect</span></h3>
                            <form method='POST' action='https://formspree.io/f/xgebapnv'>
                                <div className='relative input-field flex flex-col mb-8'>
                                    <input type="text" className='px-3 py-1 border-b-[#060606] border-b focus:outline-none' id='name' name='user_name' required />
                                    <span>Name</span>
                                </div>
                                <div className='relative input-field flex flex-col mb-8'>
                                    <input type="email" className='px-3 py-1 border-b-[#060606] border-b focus:outline-none' id='mail' name='user_email' required />
                                    <span id='ad'>Email</span>
                                </div>
                                <div className='relative input-field flex flex-col mb-5'>
                                    <textarea cols={30} className='px-3 py-1 border-b-[#060606] border-b focus:outline-none resize-none' name="message" id='message' rows={4} required ></textarea>
                                    <span>Type Your Message.....</span>
                                </div>
                                <div className='input-field flex flex-col mb-5'>
                                    <button type='submit' className='px-7 bg-[#5069a7] text-black font-medium py-3.5 rounded-sm border-[#84a4fc] duration-200 ease-in-out border w-full hover:scale-[0.98] hover:border-[#060606] hover:bg-[#060606] mr-3 hover:text-[#e6e6e6] sm:text-base text-xs'>Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>


            <footer className='sm:py-10 xl:w-[1350px] xl:mx-auto sm:px-20 xs:px-12 py-8 gap-5 xs:py-8 flex items-center justify-between xs:flex-row flex-col'>
                <p className='sm:text-sm text-xs'>Copyright © 2023 <a target='_blank' href='https://www.linkedin.com/in/nikhil-kumar-gupta-3901861b6' className='text-[#1463f3] text-base font-semibold'>@Nikhil</a>. All rights reserved</p>
                <div className='social'>
                    <ul className='flex justify-center md:justify-start'>
                        <li>
                            <a href='https://www.linkedin.com/in/nikhil-kumar-gupta-3901861b6' className='grid place-items-center p-2 text-sm border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaLinkedinIn /></a>
                        </li>
                        <li>
                            <a href='https://github.com/Nikhil-1402' className='grid place-items-center p-2 text-sm border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaGithub /></a>
                        </li>
                        <li>
                            <a href='https://twitter.com/devil_1402' className='grid place-items-center p-2 text-sm border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaTwitter /></a>
                        </li>
                        <li>
                            <a href='https://www.facebook.com/profile.php?id=100010660059501' className='grid place-items-center p-2 text-sm border mx-1 text-black bg-[#84a4fc] border-[#84a4fc] hover:bg-transparent hover:shadow-sm hover:scale-105 duration-200 ease-in-out hover:shadow-[#84a4fc] hover:text-[#e6e6e6]'><FaFacebookF /></a>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    )
}

export default Home