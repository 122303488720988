import w1 from '../../assets/projects/react/r1.png'
const ReactData = [
    {
        id: "1",
        img: w1,
        pname: 'My Portfolio',
        link: "/"
    },
]

export default ReactData;