import p1 from '../../assets/projects/htmlcss/p1.png'
import p2 from '../../assets/projects/htmlcss/p2.png'
import p3 from '../../assets/projects/htmlcss/p3.png'
import p4 from '../../assets/projects/htmlcss/p4.png'
import p5 from '../../assets/projects/htmlcss/p5.png'
import p6 from '../../assets/projects/htmlcss/p6.png'
import p7 from '../../assets/projects/htmlcss/p7.png'
import p8 from '../../assets/projects/htmlcss/p8.png'
import p9 from '../../assets/projects/htmlcss/p9.png'

const NormalData = [
    {
        id: "1",
        img: p1,
        pname: 'JS Clock',
        link: "https://nikhil-1402.github.io/analog-digital-clock.github.io/"
    },
    {
        id: "2",
        img: p2,
        pname: 'Form Validation',
        link: "https://nikhil-1402.github.io/Form-validation.github.io/"
    },
    {
        id: "3",
        img: p3,
        pname: 'JS Calculator',
        link: "https://nikhil-1402.github.io/Js-calculator/"
    },
    {
        id: "4",
        img: p4,
        pname: 'Parallax Effect',
        link: "https://nikhil-1402.github.io/parallax/"
    },
    {
        id: "5",
        img: p5,
        pname: 'Animated Background',
        link: "https://nikhil-1402.github.io/animated-background/"
    },
    {
        id: "6",
        img: p6,
        pname: 'Cursor Follower',
        link: "https://nikhil-1402.github.io/cursor-follower/"
    },
    {
        id: "7",
        img: p7,
        pname: 'Hover Effect Card',
        link: "https://nikhil-1402.github.io/card-ecommerce/"
    },
    {
        id: "8",
        img: p8,
        pname: '3D Effect Card',
        link: "https://nikhil-1402.github.io/ecommerce-card-1/"
    },
    {
        id: "9",
        img: p9,
        pname: 'Hover Effect Card',
        link: "https://nikhil-1402.github.io/ecommerce-card-2/"
    }
]

export default NormalData;